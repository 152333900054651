.record-item .quantity{
  font-size: 1.5em;
  font-weight: bold;
}

.record-item .quantity-sugar, 
.record-item .quantity-histamine {
  display: flex;
  align-items: center;
}

.record-item .quantity-sugar .quantity, 
.record-item .quantity-histamine .quantity {
  font-size: 1em;
  font-weight: bold;
}

.record-item .quantity-sugar .unit, 
.record-item .quantity-histamine .unit
{
  margin-left: 4px;
}

.record-item .details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.record-item .description {
  margin-right: 10px;
  font-size: 0.9em;
}

.record-item .measure-container {
  display: flex;
  align-items: center;
}

.measure-container-sugar{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: min-content;
}

.record-item .main-icon ion-icon,
.record-item .main-icon svg {
  font-size: 50px;
}

.record-item .measure svg {
  font-size: 2em;
}

.record-item .mood ion-icon {
  font-size: 2em;
}

.quantity-hunger {
  font-size: 24px;
  margin-right: 5px;
}

.measure-container-exercise  {
  display: flex;
  align-items: center;
}

.measure-container-exercise .icon svg {
  margin: 0 10px;
  font-size: 35px;
}
