.input-time {
    width: 50%;
}

.config-input-time {
    display: flex;
}

.config-exercise-row {
    display: flex;
    flex-direction: row;
}

.exercise-day {
    width: 100%;
}

.exercise-icon {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 10px;
}