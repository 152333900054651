.message{
  display: flex;
  flex-direction: column;
}

.message-subject{
  font-size: 1.2rem !important;
  font-weight: bold;
}

.message-text{
  font-size: 1.1rem;
  line-height: 1.6;
}

.message-name{
  width: 100%;
  text-align: right;
}
