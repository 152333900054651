.actions-container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;
  margin: 0 20px;
}

.module {
  position: relative;
  display: flex;
  align-items: center;
  height: 14%;
  max-height: 110px;
  background: linear-gradient(140deg, #066c35 0%, #318721 50%, #6ad32d 100%);
  box-shadow: 0 4px 6px #202020, 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-top: 15px;
  border-radius: 10px;
  color: white;
}

.module-inner {
  font-size: 18px;
  text-align: center;
}

.module-inner .action-icon {
  margin: 3px;
  margin-left: 8px;
}

.module-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1;
  font-size: 0.9em;
  max-height: 4em;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-left: 0.2em;
}

@media screen and (max-width: 480px) {
  .module-title {
    font-size: 0.8em;
    max-height: 3em;
  }
}

@media screen and (max-width: 320px) {
  .module-title {
    font-size: 0.7em;
    max-height: 2.5em;
  }
}

.module ion-icon {
  font-size: 32px;
  padding: 4px;
  border-radius: 25%;
  background-color:aliceblue;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.follow_up {
  display: flex;
  justify-content: center;
}
