.filters{
    display: flex;
    flex-direction: column;
}

.filters-type {
    flex-grow: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.filters-date {
    display: flex;
    justify-content: center;
}
