.item-icon svg{
  font-size: 50px;
}

.item-counter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-counter span {
  font-size: 20px;
  font-weight: bold;
}

.item-plus, .item-minus {
  border-radius: 5px;
  padding: 2px;
  margin: 0 10px;
  border-color: var(--ion-color-primary-contrast);
  border: 1px solid;
  font-size: 20px;
}
