.button-send {
    --padding-top: 1.1rem;
    --padding-bottom: 1.1rem;
    --padding-end: 1.6rem;
    --padding-start: 1.6rem;
    --ion-margin-bottom: 20px;
    --border-radius: 10px 15px 10px 15px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin {
    margin: 25px;
}
