.physical-data ion-input {
  text-align: right;
}

.exercise-goals {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.exercise-goals-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.exercise-custom-input {
  --padding-start: 0;
}

.exercise-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}

.exercise-duration {
  color: gray;
  font-size: 0.7em;
}

.goal-dates{
  text-align: right;
}

.picker-opt {
  font-size: 0.7em;
}