#notification-button {
  position: relative;
  overflow: visible !important;
}


#notifications-badge {
  position: absolute;
  top: 4px;
  right: -10px;
  border-radius: 100%;
  font-size: 10px;

  --padding-start: 6px;
  --padding-end: 6px;
}
