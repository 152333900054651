.login-logo{
  max-width: 400px;
  margin: 0 50px;

  margin-top: 10vh;
  margin-bottom: 2vh;
}

ion-button {
  --border-radius: 0;
}

html,
body {
  height: 100%;
  max-width: 500px;
}

html {
  display: table;
  margin: auto;
}

body {
  display: table-cell;
  vertical-align: middle;
}

.item-counter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-counter span {
  font-size: 20px;
  font-weight: bold;
}

.item-plus, .item-minus {
  border-radius: 5px;
  padding: 2px;
  margin: 0 10px;
  border-color: var(--ion-color-primary-contrast);
  border: 1px solid;
  font-size: 20px;
}

.reset-password {
  font-size: 14px;
}

ion-content {
  --offset-bottom: var(--keyboard-offset, 0px);
  padding-bottom: calc(var(--offset-bottom) + 16px); 
}
