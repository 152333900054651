
html,
body {
  height: 100%;
  max-width: 500px;
}

html {
  display: table;
  margin: auto;
}

body {
  display: table-cell;
  vertical-align: middle;
}

ion-button {
  --border-radius: 0;
}

.search-item-icon-container {
  margin: auto;
  margin-right: 0;
}

.search-item-icon-container ion-icon.search-item-icon {
  font-size: 26px;
  margin-right: 8px;
}

form:not(#search-filters-form) ion-label {
  margin-bottom: 0.375em !important;
}

#search-filters-container ion-backdrop {
  position: fixed;
}

#search-filters {
  position: absolute;
  border-radius: 0 0 20px 20px;
  z-index: 3;
}

#search-filters ion-label {
  margin: 0;
}

#search-filters ion-input input {
  padding: 0;
}

#search-filters ion-list,
#search-filters ion-item::part(native) {
  background-color: var(--ion-color-primary);
  color: white;
}

.toolbar-container {
  display: flex;
}

.not-visible {
  visibility: hidden;
}

.search-item-icon-container ion-icon.search-item-icon {
  font-size: 26px;
  margin-right: 8px;
}
form:not(#search-filters-form) ion-label {
  margin-bottom: 0.375em !important;
}
.save-filters {
  display: flex;
  width: 80%;
  color: white;
  margin: 16px auto;
  text-transform: uppercase;
}

.save-filters::part(native),
.reset-filters::part(native) {
  border-radius: 20px;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 2%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
}

.save-filters::part(native) {
  background: var(--ion-color-tertiary);
}

.reset-filters-container {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: 16px auto;
}

.reset-filters {
  color: white;
}

.reset-filters::part(native) {
  background: var(--ion-color-danger-shade);
}

.hidden{
  display: none;
}

.small-logo{
  width:2.6rem;
}

#root > ion-app > ion-app > ion-router-outlet > ion-fab > ion-fab-button{
  --background:#000000;
}

.disabled > .item-native:part(native){
  color:grey;
}

.button {
  --border-radius: 5px;
}
