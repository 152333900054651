.hunger-scale {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
  height: 470px;
  width: 100%;
  padding: 1em;
}

.hunger-scale .desc {
  font-size: 14px;
  margin-left: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.hunger-scale .scale {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 10%;
  padding: 2px;
  width: 100%;
}

.hunger-scale .scale span {
  height: 80%;
}

.hunger-scale .scale {
  border: 2px solid transparent;
}
.hunger-scale .scale.selected {
  border: 2px solid var(--ion-color-primary);
  border-radius: 5px;
}

.hunger-scale .scale.unselected .desc {
  color: #808080;
}

.hunger-scale [class^='scale-'] {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

[class^='hunger-value-']{
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  position: fixed;

  width: 25px;
  height: 25px !important;
  margin-right: 5px;
  border-radius: 50%;

  color: black;
}

.hunger-scale .scale-1 {
  width: 10%;
  background-color: darkred;
  box-shadow: 3px 3px 2px 2px rgba(139, 0, 0, 0.76);
}

.hunger-scale .scale-2 {
  width: 15%;
  background-color: crimson;
  box-shadow: 3px 3px 2px 2px rgba(220, 20, 60, 0.76);
}

.hunger-scale .scale-3 {
  width: 20%;
  background-color: cyan;
  box-shadow: 3px 3px 2px 2px rgba(0, 255, 255, 0.76);
}

.hunger-scale .scale-4 {
  width: 25%;
  background-color: lightgreen;
  box-shadow: 3px 3px 2px 2px rgba(144, 238, 144, 0.76);
}

.hunger-scale .scale-5 {
  width: 30%;
  background-color: green;
  box-shadow: 3px 3px 2px 2px rgba(0, 128, 0, 0.76);
}

.hunger-scale .scale-6 {
  width: 35%;
  background-color: yellow;
  box-shadow: 3px 3px 2px 2px rgba(255, 255, 0, 0.76);
}

.hunger-scale .scale-7 {
  width: 40%;
  background-color: orange;
  box-shadow: 3px 3px 2px 2px rgba(255, 166, 0, 0.76);
}

.hunger-scale .scale-8 {
  width: 45%;
  background-color: orangered;
  box-shadow: 3px 3px 2px 2px rgba(255, 68, 0, 0.76);
}

.hunger-scale .scale-9 {
  width: 50%;
  background-color: crimson;
  box-shadow: 3px 3px 2px 2px rgba(220, 20, 60, 0.76);
}

.hunger-scale .scale-10 {
  width: 55%;
  background-color: darkred;
  box-shadow: 3px 3px 2px 2px rgba(139, 0, 0, 0.76);
}
