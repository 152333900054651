.mood-selector ion-label{
  text-transform: capitalize;
}

.mood-selector .sad.segment-button-checked,
.mood-selector .sad.segment-button-checked:hover ion-icon,
.mood-selector .sad.segment-button-checked:hover ion-label,
.mood-selector .sad ion-icon:hover {
  color: salmon;
}
